import React from 'react';
import './Page2.css';
import './Page.css'; // Import the CSS animations

const Page2 = () => {
  return (
    <div className="page-container">
      <h1>About the Developer</h1>
      <div className="developer-info" style={{ color: "white" }}>
        <h2>Jose Torres</h2>
        <p style={{ color: "white" }}>
          Jose Torres is a dedicated software developer with a passion for making advanced, cutting-edge technology accessible to everyone. His journey in tech has been driven by a desire to bridge the gap between complex technology and everyday applications, empowering users to leverage these tools to their fullest potential.
        </p>
        <p style={{ color: "white" }}>
          With hands-on experience in software development, data engineering, and task automation, Jose has a proven track record of delivering innovative solutions that enhance data accuracy, efficiency, and scalability. He enjoys solving problems creatively and has a broad range of skills in programming languages and tools.
        </p>
        <p style={{ color: "white" }}>
          Jose is a lifelong learner, constantly seeking to expand his knowledge and stay current with the latest advancements in computer science. This commitment to continuous learning is essential in a field that evolves as rapidly as technology.
        </p>
        <h3>Skills and Expertise</h3>
        <ul style={{ color: "white" }}>
          <div>Full-Stack Development </div>
          <div>Artificial Intelligence and Machine Learning</div>
          <div>Blockchain and Smart Contracts</div>
          <div>Data Analysis and Visualization</div>
          <div>API Development and Integration</div>
        </ul>
        <h3>Professional Background</h3>
        <p style={{ color: "white" }}>
          Jose has worked as an Intelligence Analyst at News Corp's Global Security and Operations Center (GSOC), where he developed custom software solutions and automated tasks to improve data processing and accuracy. His role involved managing and querying large datasets, creating detailed reports, and consolidating scattered data into a unified repository to enhance accessibility.
        </p>
        <p style={{ color: "white" }}> 
          Prior to this, Jose served as a Computer Applications Instructor at Caledonian Inc., where he leveraged technology to make learning engaging and interactive. He also developed an automation system using Google Sheets API and JavaScript to manage various administrative tasks, significantly improving efficiency.
        </p>
        <h3>Education</h3>
        <p style={{ color: "white" }}>
          Jose is a candidate for a Bachelor of Science in Computer Science at the University of California, Irvine, and is currently focused on gaining practical experience. He also holds Associate degrees in Mathematics and Physics from Orange Coast College.
        </p>
        <h3>Certifications</h3>
        <p style={{ color: "white" }}>
          Jose is working towards the Microsoft Certified: Power BI Data Analyst Associate Certificate and has completed the Cisco Virtual Experience Program's Intro to Software Engineering.
        </p>
        <p style={{ color: "white" }}>
          His technical skills include A/B Testing, Agile Methodologies, AWS, C++, CSS, Data Analysis & Visualization, DAX, Excel, Full-Stack Development, Git Version Control, Google App Scripts, HTML, Java, JavaScript, Machine Learning, Object-Oriented Programming, Postman, Power BI, Power Query, Python, React, RESTful APIs, and Secure Coding Practices.
        </p>
      </div>
    </div>
  );
};

export default Page2;
