import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './components/LandingPage';
import CodePen from './components/CodePen';
import HackedText from './components/HackedText';
import Matrix from './components/Matrix';
import SoundToggle from './components/Sound';
import Footer from './components/Footer';
import GoogleAnalytics from './components/GoogleAnalytics';
import SidebarNavbar from './components/SidebarNavbar';
import RainAnimation from './components/Rain';
import AboutUs from './components/AboutUs';
import HackedTextSubHeading from './components/HackedTextSubHeading';
import Home from './components/Home';
import Page1 from './components/Page1';
import Page2 from './components/Page2';
import ChatBox from './components/ChatBox';  // Import ChatBox component

import ReactGA from 'react-ga';

const TRACKING_ID = "G-QQM4E8HZDR"; // Replace with your tracking ID
ReactGA.initialize(TRACKING_ID);

function App() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [rain, setRain] = useState(true);
  const [color, setColor] = useState('white');
  const handleToggleColor = () => {
    setColor((prevColor) => (prevColor === 'green' ? 'white' : 'green'));
  };

  return (
    <Router>
      <GoogleAnalytics />
      <div className="landing-page">
        {rain ? <RainAnimation /> : <Matrix />}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <SidebarNavbar style={{ maxWidth: "50%" }} /> */}
        </div>

        <div className="center-container">
          <HackedText color={color} />
          <HackedTextSubHeading color={color} />
        </div>
        <div className="content-wrap" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <Home />
          <Routes>
            <Route path="/" element={<Page1 />} />
            <Route path="/page2" element={<Page2 />} />
            <Route path="/page3" element={<ChatBox />} /> {/* Update route */}
          </Routes>
        </div>
        <div className="footer-push"></div>
        <Footer color={color} className="sticky-footer" />
      </div>
    </Router>
  );
}

export default App;
