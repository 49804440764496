import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <Link to="/" className="circle our-products"></Link>
      <Link to="/page2" className="circle about-dev"></Link>
      <Link to="/page3" className="circle contact-us"></Link>
    </div>
  );
};

export default Home;
