import React from "react";
import SoundToggle from "./Sound";
import "./Footer.css"; // Import the CSS file

const Footer = ({color}) => {
  return (
    <footer className="">
      <p className={color === 'green' ? 'green-text' :'white-text'}>&copy; 2024 ninjutsu.ai an AI company.</p>
    </footer>
  );
};

export default Footer;
