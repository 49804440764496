import React from 'react'
import './CodePen.css'
function CodePen() {
  return (
    <div class="content">
	<div class="spin">
		<div class="pyramid">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
		<div class="pyramid">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	</div>
</div>
  )
}

export default CodePen
