import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './ChatBox.css';

function ChatBox() {
  const [input, setInput] = useState('Under Maintenance');
  const [chatHistory, setChatHistory] = useState([]);
  const chatWindowRef = useRef(null);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || input === 'Under Maintenance') return;

    // Update the chat history with the user's message
    setChatHistory((prevChatHistory) => [
      ...prevChatHistory,
      { sender: 'user', message: input }
    ]);

    try {
      const res = await axios.post('http://localhost:8000/chat', { input });
      const botResponse = res.data.response;

      // Update the chat history with the bot's response
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        { sender: 'bot', message: botResponse }
      ]);
    } catch (error) {
      console.error('Error communicating with the API', error);
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        { sender: 'bot', message: 'Error communicating with the API' }
      ]);
    }

    setInput('');
  };

  useEffect(() => {
    // Scroll to the bottom of the chat window when chatHistory updates
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [chatHistory]);

  return (
    <div className="page-container">
    <div className="boxpaddinglol"> 

    <div className="chatbox">
      <h1>Chatbot</h1>
      <div className="chat-window" ref={chatWindowRef}>
        {chatHistory.map((chat, index) => (
          <div key={index} className={`chat-message ${chat.sender}`}>
            <span>{chat.message}</span>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder="Under Maintenance"
          disabled
        />
        <button type="submit" disabled>Send</button>
      </form>
    </div>
    </div></div>
  );
}

export default ChatBox;
