import React from 'react';
import './Page1.css';
import './Page.css'; // Import the CSS animations
import { color } from 'three/examples/jsm/nodes/Nodes.js';


const Page1 = () => {
  return (
    <div className="page-container">
      <h1 style={{ color: "white" }}>What We Do</h1>
      <div className="service">
        <h2>AI as a Service</h2>
        <p>
          Our AI as a Service (AIaaS) offers businesses the ability to leverage advanced AI technology without the need for in-house expertise. We provide customizable AI solutions tailored to meet the specific needs of your organization, ensuring that you can focus on what you do best while we handle the complexities of AI implementation.
        </p>
      </div>
      <div className="service">
        <h2>Data Analysis and Visualization</h2>
        <p>
          Transform raw data into actionable insights with our data analysis and visualization services. We use cutting-edge AI tools to help you understand your data better and make informed decisions that drive business growth.
        </p>
      </div>
      <div className="service">
        <h2>Machine Learning Models</h2>
        <p>
          Develop and deploy powerful machine learning models that can predict trends, automate processes, and provide deeper insights into your business operations. Our experts work with you to create models that deliver real value.
        </p>
      </div>
      <div className="service">
        <h2>Custom AI Solutions</h2>
        <p>
          No two businesses are the same, and neither are our AI solutions. We offer custom AI solutions designed to solve your unique business challenges. From automation to advanced analytics, we provide solutions that fit your needs.
        </p>
      </div>
    </div>
  );
};

export default Page1;
