import { useState, useEffect, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./SidebarNavbar.css";
import SoundToggle from "./Sound";

function SidebarNavbar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [menuRef]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown-menu" ref={menuRef}>
      <div className="menu-icon" onClick={toggleMenu}>
        <i className="fas fa-bars"></i>
      </div>
      {isOpen && (
        <div className="menu-links">
          <Link to="/" onClick={toggleMenu}>
            Home
          </Link>
          <Link to="/aboutus" onClick={toggleMenu}>
            About Us
          </Link>
          <Link to="/contact" onClick={toggleMenu}>
            Contact
          </Link>
        </div>
      )}
    </div>
  );
}

export default SidebarNavbar;
