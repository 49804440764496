import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./HackedTextSubHeading.css";

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

function HackedTextSubHeading({ color }) {
  const [intervalId, setIntervalId] = useState(null);
  const [isContactPage, setIsContactPage] = useState(false);
  const [text, setText] = useState('AN AI COMPANY');
  
  const onMouseOverHandler = (event) => {
    let iteration = 0;

    clearInterval(intervalId);

    const newIntervalId = setInterval(() => {
      const newText = event.target.dataset.value
        .split("")
        .map((letter, index) => {
          if (index < iteration) {
            return event.target.dataset.value[index];
          }

          return letters[Math.floor(Math.random() * 26)];
        })
        .join("");

      event.target.innerText = newText;

      if (iteration >= event.target.dataset.value.length) {
        clearInterval(newIntervalId);
      }

      iteration += 1 / 3;
    }, 30);

    setIntervalId(newIntervalId);
  };

  const onClickHandler = () => {
    setIsContactPage(!isContactPage);
  };
  const scrambleText = (originalText) => {
    let scrambledText = '';
    while (originalText.length > 0) {
      const randomIndex = Math.floor(Math.random() * originalText.length);
      scrambledText += originalText[randomIndex];
      originalText = originalText.slice(0, randomIndex) + originalText.slice(randomIndex + 1);
    }
    return scrambledText;
  };
  useEffect(() => {
    const scrambledText = scrambleText(text);
    setText(scrambledText);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const element = document.querySelector("h2[data-value='AN AI COMPANY']");
      onMouseOverHandler({ target: element });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  console.log(`color: ${color}`);

  return (
    <div className={`container-hacked-subheading ${color === "green" ? "hacked-subheading-green" : "hacked-subheading-white"}`}>
      <Link to="/" onClick={onClickHandler}>
          <h2 data-value="AN AI COMPANY" onMouseOver={onMouseOverHandler}>
            {text}
          </h2>
        </Link>
    </div>
  );
}

export default HackedTextSubHeading;
