import React, { useEffect, useState } from "react";
import "./Matrixv1.css";
import "./Matrix.css";

function Matrix() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="matrix-container">
      <ul className={`matrix ${isMobile ? 'matrix-mobile' : 'matrix-desktop'}`}>
        {[...Array(100)].map((_, i) => <li key={i}></li>)}
      </ul>
    </div>
  );
}

export default Matrix;
